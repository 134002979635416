<template>
  <div class="index">
    <!-- 心理健康记录表 -->
    <div class="searchBtn">
      <el-select v-model="searchInfo.type" placeholder="请选择类型" size="small" clearable>
        <el-option label="测评" value="测评">
        </el-option>
        <el-option label="预警" value="预警">
        </el-option>
        <el-option label="危机干预" value="危机干预">
        </el-option>
        <el-option label="咨询" value="咨询">
        </el-option>
      </el-select>
      <el-select v-model="searchInfo.gaugeId" placeholder="请选择量表" size="small" clearable>
        <el-option v-for="item in GaugeList" :key="item.id" :label="item.name" :value="item.id">
        </el-option>
      </el-select>
      <el-select v-model="searchInfo.consultId" placeholder="请选择咨询师" size="small" clearable>
        <el-option v-for="item in TeacherData" :key="item.id" :label="item.username" :value="item.id">
        </el-option>
        <!-- <el-option label="男" :value="1">
        </el-option>
        <el-option label="女" :value="2">
        </el-option>
        <el-option label="未填写" :value="3">
        </el-option> -->
      </el-select>
      <el-date-picker clearable size="small" v-model="searchInfo.startTime" type="date" placeholder="开始时间"
        value-format="yyyy-MM-dd 00:00:00" style="margin-right:10px" format="yyyy-MM-dd">
      </el-date-picker>
      <span>至</span>
      <el-date-picker clearable size="small" v-model="searchInfo.endTime" type="date" placeholder="结束时间"
        format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59">
      </el-date-picker>
      <!-- <el-input placeholder="请输入内容" size="small" v-model="searchInfo.input" clearable>
      </el-input> -->
      <el-button type="primary" size="small" @click="search">搜索</el-button>
      <el-button type="primary" size="small" @click="downloadData">导出数据</el-button>
      <el-button type="primary" size="small" @click="downloadWord">导出报告</el-button>
    </div>
    <div class="tableContent">
      <template>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="createTime" label="时间" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="type" label="类型" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="name" label="量表名称" show-overflow-tooltip>
          </el-table-column>
          <el-table-column  >
            <template slot="header" slot-scope="scope">
              <span>级别</span>
              <el-tooltip class="item" effect="dark" content="如果触发多个预警级别，只显示最高的预警级别。" placement="top">
                <i class="el-icon-question"></i>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <span v-if="scope.row.level === 1">一级</span>
              <span v-else-if="scope.row.level === 2">二级</span>
              <span v-else-if="scope.row.level === 3">三级</span>
            </template>
          </el-table-column>
          <el-table-column prop="consultName" label="咨询师姓名" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="operatorName" label="操作员姓名" show-overflow-tooltip>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="150">
            <template slot-scope="scope">
              <!-- <el-option label="测评" value="测评">
        </el-option>
        <el-option label="预警" value="预警">
        </el-option>
        <el-option label="危机干预" value="危机干预">
        </el-option>
        <el-option label="咨询" value="咨询"> -->
          <template v-if="scope.row.type === '测评'">
            <el-button type="text" size="small" @click="toReport(1,scope.row.id)">答题记录</el-button>
            <el-button type="text" size="small" @click="toReport(2,scope.row.id)">测评报告</el-button>
          </template>
          <template v-if="scope.row.type === '预警'">
            <el-button type="text" size="small" @click="toReport(3,scope.row.id)">预警报告</el-button>
          </template>
          <template v-if="scope.row.type === '危机干预'">
            <el-button type="text" size="small" @click="toReport(4,scope.row.id)">干预报告</el-button>
          </template>
          <template v-if="scope.row.type === '咨询'">
            <el-button type="text" size="small" @click="toReport(5,scope.row.id)">咨询报告</el-button>
          </template>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :current-page="paging.p" :page-size="paging.ps"
        layout="total, prev, pager, next, jumper" :total="paging.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { getGaugeList } from '@/api/gauge/gaugeList.js'
import { getTeachersList } from '@/api/dangerWarning/crisisIntervention'
import { getRecordList } from '@/api/healthRecords/filemanagement'
export default {
  data() {
    return {
      searchInfo: {
        type: null
      },
      GaugeList: [], //量表列表
      paging: {
        p: 1,
        ps: 15,
        total: 0
      }, // 分页信息
      tableData: [],
      TeacherData: [], // 咨询师数组
    }
  },
  methods: {
    // 获取量表列表
    async getGaugesList() {
      const res = await getGaugeList({ p: 1, ps: 100000, status: 2 })
      // console.log(res);
      if (res.code === 200) {
        this.GaugeList = res.data.records;
      }
    },
    // 获取健康记录表
    async getRecords() {
      let info = { p: this.paging.p, ps: this.paging.ps, userId: sessionStorage.getItem('fileUserId'), ...this.searchInfo }
      if (info.type === '') {
        info.type = null
      }
      const res = await getRecordList(info);
      console.log(res);
      if (res.code === 200) {
        this.tableData = res.data.records;
        this.paging.total = res.data.total;
      }
    },
    // 点击搜索
    search() {
      this.paging.p = 1;
      this.getRecords();
    },
    // 点击分页
    handleCurrentChange(val) {
      this.paging.p = val;
      this.getRecords();

    },
    // 获取咨询师列表
    async getTeacherList() {
      const res = await getTeachersList();
      console.log(res);
      if (res.code === 200) {
        this.TeacherData = res.data;
      }
    },
    // 查看报告
    toReport(val1, val) {
      this.$router.push({
        path: `/home/fileDetails${val1}`,
        query: {
          id: val
        }
      })
    },
    // 导出数据
    async downloadData() {
      if (this.tableData.length > 0) {
        // let arr = [];
        // this.selectList.forEach(item => {
        //   arr.push(item.id);
        // });
        // let list = arr.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/archives/export/${sessionStorage.getItem('fileUserId')}`;
        // elink.href = `http://192.168.31.135:8089/api/archives/export/${sessionStorage.getItem('fileUserId')}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '暂无可导出数据！'
        });
      }
    },
    async downloadWord() {
      if (this.tableData.length > 0) {
        // let arr = [];
        // this.selectList.forEach(item => {
        //   arr.push(item.id);
        // });
        // let list = arr.join(',');
        const elink = document.createElement('a');
        elink.href = `${this.$store.state.requestApi}/archives/export/report/${sessionStorage.getItem('fileUserId')}`;
        // elink.href = `http://192.168.31.135:8089/api/archives/export/report/${sessionStorage.getItem('fileUserId')}`;
        elink.click();
      } else {
         this.$message({
          showClose: true,
          message: '暂无可导出数据！'
        });
      }
    }
  },
  created() {
    this.getRecords();
    this.getGaugesList();
    this.getTeacherList();

  }
}
</script>

<style lang="scss" scoped>
.index {
  padding: 20px 0px;

  ::v-deep.el-date-editor {
    width: 150px !important;
  }

  .searchBtn {
    ::v-deep.el-date-editor {
      width: 150px !important;
    }

    span {
      display: inline-block;
      margin-right: 10px;
      line-height: 32px;
    }
  }

  .tableContent {
    margin-top: 20px;
  }
}
</style>